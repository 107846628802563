var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('m-widget-container-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('m-array-form',{attrs:{"name":"Slideshow Item","default-item":{ assetType: 'image', muted: true },"item-photo":"photo"},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.assetType === 'video')?_c('video',{attrs:{"src":item.src,"width":"75","height":"75","preload":"metadata"}}):_c('v-img',{attrs:{"src":_vm._f("mImage")(item.photo),"contain":""}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.assetType === 'video')?[_vm._v(" "+_vm._s(item.src ? item.src.split('/').reverse()[0].split('%2F').reverse()[0].split('?')[0] : 'Nicht gefunden')+" ")]:[_vm._v(" "+_vm._s((item.photo ? item.photo.src || item.photo : '').split('/').reverse()[0].split('%2F').reverse()[0].split('?')[0])+" ")]]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('field-card',{attrs:{"label":"Slideshow Item"}},[_c('v-radio-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Asset type")])]},proxy:true}],null,true),model:{value:(editItem.assetType),callback:function ($$v) {_vm.$set(editItem, "assetType", $$v)},expression:"editItem.assetType"}},[_c('v-radio',{attrs:{"label":"Photo","value":"image"}}),_c('v-radio',{attrs:{"label":"Video","value":"video"}})],1),(editItem.assetType === 'video')?[_c('asset-manager',{attrs:{"asset-type":"video"},model:{value:(editItem.src),callback:function ($$v) {_vm.$set(editItem, "src", $$v)},expression:"editItem.src"}})]:[_c('asset-manager',{attrs:{"asset-type":"image"},model:{value:(editItem.photo),callback:function ($$v) {_vm.$set(editItem, "photo", $$v)},expression:"editItem.photo"}}),_c('v-text-field',{attrs:{"label":"Duration On Screen","outlined":"","hint":("Leave empty to keep the default " + (_vm.value.intervalInSeconds) + " second duration setting on the slideshow."),"persistent-hint":""},model:{value:(editItem.duration),callback:function ($$v) {_vm.$set(editItem, "duration", $$v)},expression:"editItem.duration"}})]],2)]}}],null,true),model:{value:(_vm.value.items),callback:function ($$v) {_vm.$set(_vm.value, "items", $$v)},expression:"value.items"}}),_c('field-card',{attrs:{"label":"Slideshow Configuration"}},[_c('v-switch',{attrs:{"label":"Disable Touch Swipe"},model:{value:(_vm.value.touchless),callback:function ($$v) {_vm.$set(_vm.value, "touchless", $$v)},expression:"value.touchless"}}),(_vm.hasImage)?[_c('v-subheader',{domProps:{"textContent":_vm._s('Photo Options')}}),_c('validation-provider',{attrs:{"rules":"required","name":"Seconds per Slide"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Seconds per Slide","required":"","error-messages":errors},model:{value:(_vm.value.intervalInSeconds),callback:function ($$v) {_vm.$set(_vm.value, "intervalInSeconds", $$v)},expression:"value.intervalInSeconds"}})]}}],null,false,944987812)})]:_vm._e(),(_vm.hasVideo)?[_c('v-subheader',{domProps:{"textContent":_vm._s('Video Options')}}),_c('v-switch',{staticClass:"ma-2 mr-4",attrs:{"label":"Mute Videos"},model:{value:(_vm.value.videosMuted),callback:function ($$v) {_vm.$set(_vm.value, "videosMuted", $$v)},expression:"value.videosMuted"}})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }