<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <m-array-form
        v-model="value.items"
        name="Slideshow Item"
        :default-item="{ assetType: 'image', muted: true }"
        item-photo="photo"
      >
        <template #[`item.photo`]="{ item }">
          <video
            v-if="item.assetType === 'video'"
            :src="item.src"
            width="75"
            height="75"
            preload="metadata"
          />
          <v-img
            v-else
            :src="item.photo | mImage"
            contain
          />
        </template>
        <template #[`item.title`]="{ item }">
          <template v-if="item.assetType === 'video'">
            {{ item.src ? item.src.split('/').reverse()[0].split('%2F').reverse()[0].split('?')[0] : 'Nicht gefunden' }}
          </template>
          <template v-else>
            {{ (item.photo ? item.photo.src || item.photo : '').split('/').reverse()[0].split('%2F').reverse()[0].split('?')[0] }}
          </template>
        </template>
        <template #default="{ editItem }">
          <field-card label="Slideshow Item">
            <v-radio-group v-model="editItem.assetType">
              <template #label>
                <div>Asset type</div>
              </template>
              <v-radio
                label="Photo"
                value="image"
              />
              <v-radio
                label="Video"
                value="video"
              />
            </v-radio-group>
            <template v-if="editItem.assetType === 'video'">
              <asset-manager v-model="editItem.src" asset-type="video" />
            </template>
            <template v-else>
              <asset-manager v-model="editItem.photo" asset-type="image" />
              <v-text-field
                v-model="editItem.duration"
                label="Duration On Screen"
                outlined
                :hint="`Leave empty to keep the default ${value.intervalInSeconds} second duration setting on the slideshow.`"
                persistent-hint
              />
            </template>
          </field-card>
        </template>
      </m-array-form>
      <field-card label="Slideshow Configuration">
        <v-switch
          v-model="value.touchless"
          label="Disable Touch Swipe"
        />
        <template v-if="hasImage">
          <v-subheader v-text="'Photo Options'" />
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Seconds per Slide"
          >
            <v-text-field
              v-model="value.intervalInSeconds"
              outlined
              type="number"
              label="Seconds per Slide"
              required
              :error-messages="errors"
            />
          </validation-provider>
        </template>
        <template v-if="hasVideo">
          <v-subheader v-text="'Video Options'" />
          <v-switch
            v-model="value.videosMuted"
            class="ma-2 mr-4"
            label="Mute Videos"
          />
        </template>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MSlideshowForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  }),
  computed: {
    hasVideo() {
      return this.value.items.some(x => x.assetType === 'video')
    },
    hasImage() {
      return this.value.items.some(x => x.assetType !== 'video')
    }
  }
}
</script>
